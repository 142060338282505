@import '@nextgen-web-framework/all/amway-design-system.scss';

.content {
  flex-grow: 1;

  min-height: 2em;
}

.site-navigation-header {
  z-index: 4;

  & > header {
    min-height: 44px;
  }
}

.notificationbar-header {
  z-index: 4;
}
.notificationbar-error-header {
  position: sticky;
  top: 0;
  z-index: 5;
}

.mega-menu-header-container {
  position: sticky;
  top: 0px;
  z-index: 3;
}

.mega-menu-header-container-with-impersonation {
  position: sticky;
  top: 52px;
  z-index: 3;
}

.error_notification_top_padding {
  top: 40px;
}

.mepanel-drawer-on-open {
  z-index: 4;
}

.footer-section {
  padding-bottom: 0;
}

.footer-alignment {
  margin-top: 20px;
}

.container {
  width: 100%;
  @include display-behaviour(flex);
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 1440px;
  margin: 0 auto;

  & > header {
    flex-shrink: 0;
  }

  .tab-header-controll {
    .site-navigation-header {
      @include display-behaviour($display-none);
    }
  }
}

.breadcrumb-container {
  ul {
    padding: 12px 48px;
    margin: 0px;
  }
}

@include sm-mobile {
  .container {
    div[class*='sticky-bottom-bar'] {
      bottom: calc(0px + env(safe-area-inset-bottom, 0px));
    }
  }
  .sticky-top-bar-positions-with-error {
    header[class*='sticky-top-bar-positions'] {
      top: 120px;
    }
  }
  .mega-menu-header-container-with-impersonation {
    top: 80px;
  }
  .tab-header-controll {
    .site-navigation-header {
      @include display-behaviour($display-none);
    }
  }

  .breadcrumb-container {
    ul {
      padding: 16px 32px;
      margin: 0px;
    }
  }
}

@include xs-mobile() {
  .mobile-topbar-navigation-header-style {
    position: relative;
  }
  .sticky-top-bar-positions-with-error {
    header[class*='sticky-top-bar-positions'] {
      top: 123px;
    }
    div[class*='shop-by-navigator-positions'] {
      top: 120px;
    }
  }
  .container {
    div[class*='sticky-bottom-bar'] {
      bottom: calc(65px + env(safe-area-inset-bottom, 0px));
    }
    .content-on-scroll {
      div[class*='sticky-bottom-bar'] {
        bottom: 0;
        padding-bottom: calc(16px + env(safe-area-inset-bottom, 0px));
      }
    }
    .hide-header {
      .header-container {
        @include display-behaviour($display-none);
      }
    }

    .site-navigation-header {
      @include display-behaviour($display-none);
    }

    .mobile-topbar-navigation-header-style {
      position: sticky;
      top: 0px;
      z-index: 3;
      width: 100%;

      .mobile-top-header-container {
        z-index: 1;
      }
    }
    .header-height {
      height: 96px;
    }
    .error_notification_top_padding {
      top: 64px;
    }
  }

  .footer-section {
    padding-bottom: 80px;
  }

  .footer-alignment {
    margin-top: 20px;
  }
}

.header-container-on-scroll {
  box-shadow: 0px 3px 2px -2px rgba(44, 44, 44, 0.1);
}

@include xs-mobile() {
  .notificationbar-header {
    z-index: 0;
  }
  .mobilBottom-header-on-scroll {
    @include display-behaviour($display-none);
  }
  .notificationbar-error-header {
    z-index: 3;
  }
}
