@import '@nextgen-web-framework/all/amway-design-system.scss';

.homepage-profile-stepper {
  margin: 40px 48px;
  min-height: 230px;
}

@include sm-mobile() {
  .homepage-profile-stepper {
    margin: 24px;
  }
}

@include xs-mobile() {
  .homepage-profile-stepper {
    margin: 16px;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position-x: 0;
  }
  80% {
    background-position-x: 100%;
  }
}

.skeleton-loader-container > div {
  background: linear-gradient(90deg, $light-gray-rule-lines 0.19%, #e4e4e4d3 21.92%, #e4e4e400 100.19%);
  background-size: 400% 100%;
  animation: skeleton-loading 1s ease-in-out infinite;
  margin-left: 0;
  margin-right: 0;
  height: 230px;
}
