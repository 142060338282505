@import '@nextgen-web-framework/all/amway-design-system.scss';

.centered-container {
  padding: 0 48px 32px 48px;
}

@include sm-mobile() {
  .centered-container {
    padding: 0 32px 32px 32px;
  }
}

@include xs-mobile() {
  .centered-container {
    padding: 0 16px 32px 16px;
  }
}

.full-width-container {
  padding: 0 0 32px 0;
}

.standard-carousel-container {
  padding: 0 0 32px 32px;
}

@include sm-mobile() {
  .standard-carousel-container {
    padding: 0 0 32px 16px;
  }
}

@include xs-mobile() {
  .standard-carousel-container {
    padding: 0 0 32px 0;
  }
}
