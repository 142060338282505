@import '@nextgen-web-framework/all/amway-design-system.scss';

.home-container {
  position: relative;
  @include flexbox-positional-style($display-flex, space-between, center);
  flex-direction: column;
  padding: 0px;
  gap: 16px;

  .img-container {
    position: relative;
    width: 100%;
    object-fit: cover;
  }
}

.page-banner {
  padding-bottom: 32px;
}
