@import '@nextgen-web-framework/all/amway-design-system.scss';
.column-based-component-container {
  padding-bottom: 32px;
  grid-column: 2 / -2;
}

@include sm-mobile() {
  .column-based-component-container {
    grid-column: 1 / -1;
  }
}

@include xs-mobile() {
  .column-based-component-container {
    grid-column: 1 / -1;
  }
}

.carousel-component-container {
  padding-bottom: 32px;
  grid-column: 2 / -2;
  margin-left: -16px;
}

@include sm-mobile() {
  .carousel-component-container {
    grid-column: 1 / -1;
    margin: 0 -32px 0 -16px;
  }
}

@include xs-mobile() {
  .carousel-component-container {
    grid-column: 1 / -1;
    margin: 0 -16px;
  }
}

.full-width-container {
  padding-bottom: 32px;
  grid-column: 2 / -2;
}

@include sm-mobile() {
  .full-width-container {
    grid-column: 1 / -1;
    margin: 0 -32px;
  }
}

@include xs-mobile() {
  .full-width-container {
    grid-column: 1 / -1;
    margin: 0 -16px;
  }
}
