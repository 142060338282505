@import '@nextgen-web-framework/all/amway-design-system.scss';

.centered-container {
  padding-bottom: 32px;
}

.full-width-container {
  padding-bottom: 32px;
}

.standard-carousel-container {
  padding-bottom: 32px;
}

.hero-component-container {
  padding-bottom: 32px;
}
